<template>
	<div>
		<main-header></main-header>
		<h1 class="page-title" v-if="computedValue === `ko`">회원가입</h1>
		<h1 class="page-title" v-else-if="computedValue === `en`">Sign Up</h1>
		<router-view @setId="setId" :id="id"></router-view>
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';

export default {
	data() {
		return {
			id: '',
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},
	components: { MainHeader, Footer },
	methods: {
		setId(id) {
			this.id = id;
		},
	},
};
</script>

<style></style>
